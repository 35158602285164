.covid19-wrapper {
    padding: 10px 1%;
}

.covid19-max-label {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 59px;
    color: #E76F51;
}

.covid19-max-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
}

.covid19-current-label {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 59px;
    color: #2A9D8F;
}

.covid19-current-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    text-align: start;
    margin-top: 9px;
}

.covid19-current-bottom {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #E76F51;
}

.covid19-capacity-box {
    background: #FFFFFF !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    width: 163px;
    height: 105px;
    position: relative;
}

.covid19-capacity-current-box {
    background: #FFFFFF !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    width: 215px;
    height: 105px;
}

.covid19-checkIn-box {
    padding: 15% 7%;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    max-width: 550px;
    max-height: 439px;
}

.covid19-checkIn-title-wrapper {
    padding-bottom: 25px;
}

.covid19-checkIn-btn-wrapper {
    padding-top: 25px;
}

.covid19-checkIn-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    margin-bottom: 25px;
}

.covid19-checkout-box {
    padding: 5%;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    margin-top: 21px;
    max-width: 550px;
    max-height: 439px;
}

.covid19-checkout-box-notFound {
    padding: 5%;
    text-align: center;
    background: #FFFFFF;
    border-radius: 2px;
    margin-top: 21px;
    max-width: 550px;
    max-height: 439px;
}

.covid19-checkout-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    margin-bottom: 15px;
}

.covid19-main-wrapper {
    padding: 7% 15%
}

.covid19-usersFound-wrapper {
    padding-top: 46px;
}

.covid19-usersFound {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    width: 200px;
    margin: 0 auto;
}

.covid19-userFound-box {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    margin-top: 21px;
    max-width: 550px;
}

.covid19-member {
    background: #333333;
    color: #FFFFFF;
    height: 112px;
    padding-top: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
}

.covid19-guest {
    background: #E76F51;
    color: #FFFFFF;
    height: 112px;
    padding-top: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
}

.covid19-staff {
    background: #E9C46A;
    color: #FFFFFF;
    height: 112px;
    padding-top: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
}

.covid19-userFound-name {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
}

.covid19-userFound-email {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #414158;
}

.covid19-userFound-phone {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #414158;
}

.covid19-userFound-info {
    padding: 23px 5px 19px 15px;
    max-height: 112px;
}

.covid19-userFound-btn {
    align-self: center;
    max-height: 112px;
}

.covid19-userFound-icon {
    margin-right: 7px;
    margin-top: -3px;
}

.covid19-skip-btn {
    text-align: end;
}

.covid19-screening-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-top: 17px;
}

.covid19-screening-box {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    margin-top: 20px;
    padding: 12px 24px; 
}

.covid19-screening-token {
    width: 29px;
    height: 29px;
}

.covid19-screening-name {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: rgba(32, 47, 53, 0.5);
    padding-left: 16px;
    padding-top: 3px;
}

.covid19-screening-alert {
    text-align: end;
    padding-left: 5px;
}

.covid19-screening-subTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #E76F51;
}

.covid19-screening-spacing {
    padding: 0 5% 0 5%;
}

.covid19-screening-questions {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}


.covid19-screening-box-wrapper {
    position: relative;
    height: 5em;
}

.covid19-screening-box {
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 12px 24px; 
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.covid19-screening-token {
    align-self: center;
}



.covid19-checkInResult-success {
    background: #2A9D8F;
    height: 186px;
}

.covid19-checkInResult-declined {
    background: #D52B1E;
    height: 186px;
}

.covid19-checkInResult-box {
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    margin-top: 21px;
    max-width: 425px;
    max-height: 439px;
}

.covid19-checkInResult-img {
    margin-top: 35px;
    margin-bottom: 25px;
}

.covid19-checkInResult-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 19px;
    color: #FFFFFF;
}

.covid19-checkInResult-subTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
    padding-top: 29px;
    padding-bottom: 32px;
}

.covid19-checkInResult-negative {
    color: #2A9D8F;
}

.covid19-checkInResult-positive {
    color: #D52B1E;
}

.covid19-checkInResult-text {
    padding:0 48px 35px 48px;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
}


.covid19-stepper-box {
    width: 80%;
    margin-top: 3%;
    max-width: 650px;
}

.covid19-guestForm-box {
    width: 60%;
    margin-top: 3%;
    max-width: 574px;
}

.covid19-guestForm-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-align: start;
    color: #E76F51;
}

.covid19-addGuest-questions {
    padding-right: 8%;
    padding-left: 8%;
    padding-top: 40px;
}

.covid19-guestForm-form {
    margin-top: 5px !important;
}

.covid19-checkout-alert {
    position: relative;
    margin: 0px;
    height: 300px;
    text-align: center;
}

.covid19-changeCapacity-alert {
    position: relative;
    margin: 0px;
    height: 180px;
    text-align: center;
}

.covid19-checkout-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #000000;
}

.covid19-checkout-close {
    position: absolute;
    top:0px;
    right: 0px;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor:pointer;
    background: none;
    border: none;
}

.covid19-checkout-confirm {
    padding-top: 35px;
}

.covid19-error-message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #E76F51;
}

.covid19-prescreening-missingInformation {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #D52B1E;
}

.covid19-change-capacity {
    position: absolute;
    right: -11px;
    top: -5px;
}


/*Media queries*/

@media only screen and (max-width: 600px) {
    .covid19-main-wrapper {
        padding: 4% 3%;
    }
    .covid19-userFound-btn {
        text-align: center;
        padding: 10px;
    }
    .covid19-guestForm-box {
        width: 100%;
    }
    .covid19-stepper-box {
        width: 100%;
    }
    .covid19-guestForm-title {
        font-size: 16px;
        text-align: center;
    }
    .covid19-addGuest-questions {
        padding: 0;
    }
    
  }