.changeCard-spacing {
  padding-left:30px;
  padding-right: 30px;
  padding-top: 15px;;
}

.Membership-card {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-top: 25px !important;
}

.Back-changeCard{ 
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
}

.ChangeCard-btn {
  min-height: 20vh;
  padding: 20px
}

.ChangeCard-btn-done{
  margin-top: 20vh;
}



@media only screen and (max-width: 900px) {
  .logo-success{
      text-align: center
  }
  .ChangeCard-btn {
    min-height: 20vh;
    padding: 0px
  }
  
  .ChangeCard-btn-done{
    margin-top: 10vh;
  }

}

