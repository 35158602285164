.success-row {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.success-spacing {
  padding: 30px;
}

.Successful-title {
  font-size: 30px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 3% !important;
}

.Successful-paragraph {
  font-size: 20px;
  color: #333333;
  margin-bottom: 5% !important;
}

.Successful-paragraph2 {
  font-size: 20px;
  color: #333333;
  margin-bottom: 2% !important;
}

.Successfull-messageBox {
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #cecece;
  background-color: rgba(255, 255, 255, 0.03);
  padding: 15px;
  margin-bottom: 2% !important;
}

.Successful-confirmation {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 3% !important;
}

.Successful-email {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 3% !important;
}

.Successful-print {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 3% !important;
  margin-top: 20px !important;
}

.Success-email-label {
  font-size: 16px;
  color: #202f35;
  margin-top: 9px !important;
}

.Success-done-btn {
  margin-top: 20px !important;
}

.Success-approved-icon {
  color: rgba(42, 157, 143, 0.83);
}

.Success-approved-text {
  color: rgba(42, 157, 143, 0.83);
  margin-left: 15px;
  padding-top: 5px;
}
.Successful-membershipMessage {
  color: rgba(42, 157, 143, 0.83);
  font-weight: bold;
  margin-bottom: 1% !important;
}

@media only screen and (max-width: 1200px) {
  .success-row {
    margin-top: 20px;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .logo-success {
    text-align: center;
  }
  .Successful-title {
    text-align: center;
  }
  .Successful-paragraph {
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .logo-success {
    text-align: center;
  }
  .success-row {
    margin-top: 20px;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .sendEmail-btn {
    padding-left: 0px !important;
  }
}

.Print-Receipt {
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-top: 4.3%;
}

.Choose-a-Method-to-S {
  width: 434px;
  height: 24px;
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-top: 15px !important;
}

.input-success {
  border-radius: 3px !important ;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.14) !important ;
  background-color: #fbfbfb !important ;
  height: 45px !important;
}

.btn-success-page {
  margin-top: 55px !important;
}

.waiting-wrapper {
  padding: 10% 5%;
}

.waiting-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #333333;
}

.waiting-text-body1 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  margin-top: 15px;
  max-width: 560px;
}
.waiting-text-body2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  max-width: 560px;
  margin-top: 15px;
}

.waiting-subTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-top: 4%;
}

.waiting-box {
  border: 1px solid #cecece;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  padding: 4px 12px 0px 12px;
  margin-top: 15px !important;
  max-width: 700px !important;
}

.waiting-done-btn {
  max-width: 700px !important;
}

.bootstrapField-spacing {
  margin-bottom: 25px !important;
  margin-top: 25px !important;
}

.bootstrapField-spacing-error {
  margin-top: 25px !important;
}

.bootstrap-fix-top {
  margin-top: -26px;
}

@media only screen and (max-width: 600px) {
  .waitList-email-mobile {
    margin-top: 20px !important;
  }
}
