#NO {
  display: none;
  visibility: hidden;
}

.hidden_item {
  display: none;
  visibility: hidden;
}

.header-mobile-menu {
  position: absolute;
  cursor: pointer;
  top: 16px;
}

.custom_links {
  text-decoration: none; 
}


.custom_links:focus {
  text-decoration: none; 
  outline-color: white;
  outline-offset: -4px;
  box-shadow: none;
  border: none;
  margin: 0px;
}

.custom_links:focus .MuiListItem-root {
  background: none !important;
  color: white !important;
}

.custom_links:hover {
  text-decoration: none;
}

.mobileNav {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #2a9d8f;
  overflow-x: hidden;
  transition: 0s;
  padding-top: 50px;
  display: inline;
  visibility: visible;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 36px;
  margin-left: 50px;
  color: #f1f1f1;
  cursor: pointer;
}

.sidebar {
  background: #4b9d8f;
  width: 80px !important;
  min-height: 100vh;
}

.sideBar-subTabs {
  background: #ffffff !important;
  border: 1px solid #676779 !important;
  color: #414158 !important;
}

@media only screen and (max-width: 1100px) {
  .sidebar {
    visibility: hidden;
    display: none;
  }
}
