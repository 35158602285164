.dashboard-background {
  background-color: rgba(237, 237, 237, 0.3);
}

.dashboard-tabs-spacing {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.dashboard-tabs-spacing-newMember {
  padding-top: 25px !important;
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.graph-no-data {
  padding-top: 10%;
  padding-bottom: 10%;
}

.dashboard-spacing {
  padding-right: 32px !important;
  padding-left: 32px !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.dashboard-tabs-paper{
  padding:20px;
  position: relative;
}

.dashboard-title{
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 21px !important;
}

.dashboard-activeTab {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: #e76f51;
  cursor: pointer;
}

.dashboard-deactiveTab {
  font-size: 18px;
  line-height: 1.33;
  color: #737c80;
  cursor: pointer;
}

.dashboard-label-dropdown {
  margin-right: 5px;
}

.dashboard-item-dropdown {
  width: 10px !important;
  margin-left: -15px !important;
  margin-top: 5px !important;
}



.dashboard-tabSpacing{
  border-radius: 2px;
  background-color: rgba(237, 237, 237, 0.3);
  padding-left: 5px;
  padding-right: 5px;
}

.dashboard-tabs-title{
  font-size: 20px;
  font-weight: 600;
  color: #414158;
  margin-bottom: 10px !important;
}

.dashboard-tabs-datashow{
  font-size: 14px;
  line-height: 1.43;
  color: #333333;
  text-align: end;
}

.dashboard-tabs-box-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #333333;
  margin-bottom: 35px !important;
}

.dashboard-tabs-box-text {
  font-size: 35px;
  line-height: 0.91;
  color: #414158;
}

.dashboard-tabs-box-subText {
  font-size: 16px;
  font-weight: 500;
  line-height: 2.29;
  text-align: end;
}

.dashboard-tabs-box-arrowUp{
  color: #2a9d8f;
  margin-left: 10px;
}

.dashboard-tabs-box-arrowDown{
  color:#e24c4b;
  margin-left: 10px;
}

.dashboard-tabs-box-bottom{
  font-size: 14px;
  font-weight: 300;
  color: #333333;
  text-align: end;
}

.icon-active{
  color: #2a9d8f;
  margin-right: 8px;
}
.icon-expiring{
  color: #e9c46a;
  margin-right: 8px;
}
.icon-expired{
  color: #e76f51;
  margin-right: 8px;
}
.icon-adult{
  color:#414158;
  margin-right: 8px;
}
.icon-family{
  color:#3F69AA;
  margin-right: 8px;
}

.dashboard-tabs-title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.dashboard-tabs-viewAll {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #2a9d8f;
  cursor: pointer;
  width: 65px;
}


.dashboard-hasSpotsLeft{
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 16px;
    color: #ffffff;
    font-family: 'Work Sans', sans-serif;
    border-radius: 12px;
    margin-right: 20px !important
  }

  .dashboard-thereISNoSpotsLeft{
    background-color: #e6e6e6;
    font-size: 16px;
    color: #000000;
    font-family: 'Work Sans', sans-serif;
    border-radius: 12px;
    margin-right: 20px !important
}

.stackedBarChart .recharts-wrapper .recharts-legend-wrapper {
  top:12px !important;
}

.stackedBarChart .recharts-wrapper .recharts-legend-wrapper .recharts-legend-item {
  margin-right: 40px !important;
}

.dashboard-totalVisits {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #2A9D8F;
  transform: rotate(270deg);
  transform-origin: left top 0;
  position: absolute;
  top: 55%;
}

