.registrationList-spacing {
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-top: 25px !important;
}

.registrationList-back {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  max-width: 150px;
  margin-bottom: 15px;
}

.registrationList-btn-createNew {
  margin-right: 20px;
}

.registrationList-header {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px !important ;
}

.registrationList-box {
  display: inline;
}

.registrationList-filter {
  cursor: pointer;
  width: 80px;
}

.registrationList-filter-space {
  margin-top: 22px !important;
}

.registrationList-activeList {
  font-size: 18px;
  font-weight: 600;
  color: #e76f51;
  display: inline;
  cursor: pointer;
}

.registrationList-deactivatedList {
  font-size: 18px;
  color: #737c80;
  display: inline;
  cursor: pointer;
}

.registrationList-searchField {
  margin-top: 20px !important;
}

.registrationList-canceled {
  background-color: #e76f51;
  font-size: 12px;
  max-width: 120px;
  color: #ffffff;
  border-radius: 12px;
  padding: 6px;
  margin: 0px;
  text-transform: capitalize;
}

.registrationList-paid {
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 12px;
  max-width: 120px;
  color: #ffffff;
  border-radius: 12px;
  padding: 6px;
  margin: 0px;
}

.registrationList-numberOfAttendees {
  margin: 0 auto;
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 12px;
  max-width: 58px;
  color: #ffffff;
  border-radius: 13px;
  padding: 6px;
}

.registrationList-pending {
  background-color: #e9c46a;
  font-size: 12px;
  max-width: 120px;
  color: #ffffff;
  border-radius: 12px;
  padding: 6px;
  margin: 0px;
}

.settings-delete-title {
  font-family: 'Work Sans', 'sans-serif';
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.registrationList-delete-body {
  font-family: 'Work Sans', 'sans-serif';
  margin-top: 5px;
  color: #737c80;
  font-size: 18px;
  padding-left: 5px;
}
.registrationList-checkbox {
  margin-top: 10px !important;
  color: #737c80;
  font-size: 18px;
  padding-left: 5px;
}

.registrationList-btns-body-delete {
  font-family: 'Work Sans', 'sans-serif';
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.registrationList-weekly-wrapper {
  padding-top: 15px !important;
  padding-right: 20px;
}

.registrationList-weekly-box {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  padding: 10px;
}

.registrationList-weekly-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 0px;
}

.registrationList-help-icon {
  color: #2a9d8f;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 18px !important;
}

.registrationList-weekly-options {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #737c80;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 600;
  border-radius: 4px;
  border: 2px solid transparent;
  padding: 5px;
}

.registrationList-weekly-options:hover {
  background: #41415840;
  color:#414158;
}

.registrationList-weekly-options:focus {
  border: 2px solid #414158;
  outline: none;
}

.registrationList-weekly-options-active {
  color:#e76f51;
  font-weight: bold;
  text-decoration: underline;
}

.registrationList-weekly-options-active:focus {
  border: 2px solid #e76f51;
}

.registrationList-weekly-options-active:hover {
  border: 2px solid #414158;
}
