.programRegistration-wrapper {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 15px;
}


.programRegistration-header {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px ;
}



.programRegistration-titles{
  font-size: 24px;
  color: #3c3c3c;
  margin-bottom: 10px ;
}


.programRegistration-membership-box {
  padding: 15px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  position: relative;
}

.programRegistration-membership-box-title {
  font-weight: 600;
  color: #202f35;
  font-size: 18px;
}


.programRegistration-membership-box-subTitle {
  font-weight: 600;
  color: #202f35;
  font-size: 16px;
  display: inline;
  margin-right: 5px;
}

.programRegistration-box-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  box-shadow: none;
  border: none;
}

.programRegistration-box-close-icon:focus {
  outline: none;
  box-shadow: 0 0 0 2px #e76f50;
  border-radius: 2px;
}

.boxes-divider {
  margin-left: 5px;
  margin-right: 5px;
}

.programRegistration-medical-fields {
  padding-top: 10px;
  padding-bottom: 10px;
}

.programRegistration-medical-titles {
  color: #202f35;
  font-size: 18px;
  display: inline;
  margin-right: 5px;
}

.medical-check-wrapper {
  color: #4b9d8f;
  position: absolute;
  right: 51px;
  top: 10px;
}

.medical-check-text {
  vertical-align: middle;
  margin-left: 3px;
}

.programRegistration-spacing {
    padding-right: 35px !important;
    padding-left: 35px !important;
    padding-top: 25px !important;
}

.programRegistration-body-spacing {
    padding-right: 35px !important;
    padding-left: 35px !important;
}

.programRegistration-back {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  max-width: 170px;
  margin-bottom: 15px;
}

.programRegistration-btns {
  padding-bottom: 23px;
  padding-top: 23px;
}

.programRegistration-title{
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 13px !important;
}

.programRegistration-deleteBtn {
    margin-top: 15px !important;
}

.programRegistration-memberFound{
    border: solid 1px #cecece;
    background-color: rgba(255, 255, 255, 0.03);
    padding: 15px;
    position: relative;
}

.programRegistration-memberFound-title {
    font-weight: 600;
    color: #202f35;
}

.programRegistration-memberFound-Familytitle {
    font-weight: 600;
    color: #202f35;
    font-size: 20px;
}

.programRegistration-memberFound-text {
    font-size: 14px;
    line-height: 1.29;
}

.programRegistration-textFields {
    margin-right: 15px !important;
}



.program-registration-text-header{
    font-family: 'Work Sans', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
    margin-top: 10px !important;
}



.member-found-icon{
    position:absolute !important;
    right:8px;
    top:8px;
    cursor:pointer;
}

.familyMember-delete-icon{
    position:absolute !important;
    right:20px;
    top:20px;
    cursor:pointer;
}

.parent-dividers{
    padding-left: 5px !important;
    padding-right: 5px !important;

}

.parent-sub-dividers{
    padding-right: 0px !important;
}

.parental-header{
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    width: 100%;
    margin-top: 12px !important; 
    margin-left: 8px !important;
    margin-right: 8px !important
}

@media only screen and (max-width: 900px) {
    .program-registration-text-header{
        margin-bottom: 5px !important;
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

}

@media only screen and (max-width: 960px) {
   .programRegistration-btns {
        padding-right: 15px;
    }
    .programRegistration-body-spacing {
        padding-right: 17px !important;
    }

}

@media only screen and (max-width: 700px) {
  .medical-check-wrapper {
    position: unset;
    margin-left: 10px;
    vertical-align: text-bottom;
  }
  
  .medical-check-text {
    display: none;
    visibility: hidden;
  }  
}