.addNewProgram-wrapper {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 15px;
}

.addNewProgram-titles {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.addNewProgram-sub-titles {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0px;
}

.addNewProgram-error-box {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  width: 300px;
  margin-top: 5% !important;
  margin-bottom: 10% !important;
}

.addNewProgram-error-title {
  background-color: #E76F51;
  padding: 20px;
}

.addNewProgram-error-text {
  color: white;
  font-weight: 600;
  margin-top: 10px;
}

.addNewProgram-error-message {
  padding: 30px 20px;
 font-weight: 600;
}

/* .addNewProgram-error-message {
  font-weight: 500;
  font-size: 20px;
} */

.addNewProgram-days {
  padding-top: 0px !important;
}

.addNewProgram-days-error {
  color: #f44336;
}

.addNewProgram-box {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  position: relative !important;
  padding: 36px 70px 15px 15px;
  margin-bottom: 25px !important;
}

.addNewProgram-box-title {
  position: absolute;
  top: 5px;
  left: 16px;
  padding: 3px;
  border: none;
  box-shadow: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #202f35;
}

.addNewProgram-box-close-button {
  border: none;
  box-shadow: none;
  background: none;
  position: absolute;
  right: 13px;
  top: 47px;
}

.addNewProgram-box-close-button:focus {
  outline-color: #E76F51;
}

.addNewProgram-weekly-schedule-box {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  position: relative !important;
  padding: 14px 24px 7px 24px !important;
  margin-bottom: 25px !important;
}

.addNewProgram-weekly-schedule-date {
  padding-bottom: 8px;
}

.addNewProgram-programStaff-box {
  padding: 15px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  position: relative;
}

.addNewProgram-programStaff-box-title {
  font-weight: 600;
  color: #202f35;
  font-size: 18px;
}


.addNewProgram-programStaff-box-subTitle {
  font-weight: 600;
  color: #202f35;
  font-size: 16px;
  display: inline;
  margin-right: 5px;
}

.addNewProgram-box-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  box-shadow: none;
  border: none;
}

.addNewProgram-box-close-icon:focus {
  outline: none;
  box-shadow: 0 0 0 2px #e76f50;
  border-radius: 2px;
}

.addNewProgram-loading {
  position: absolute;
  left: 55%;
  top: 63%
}