.back-to-verify{
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  width: 180px;
  cursor: pointer;
}
.address-verify-title{
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-top: 25px;
}
.verify-header-spacing{
  padding: 30px;
}
.verify-memberInfo-spacing{
  padding: 20px;
}
.verify-editPassword-match{
  color: green
}
.verify-editPassword-notMatch{
  color: red
}
.verify-serverResponse{
  color: red;
  text-align: center;
}
.circle-verify-address{
  font-size:35px !important;
  color: #e76f51 !important;
}
.Address-Verified {
  font-size: 14px;
  line-height: 1.43;
  color: #e76f51;
  margin: 0 auto;
  display: inline-block;
  margin-bottom: 10px;
  padding-left: 10px;
  vertical-align: super;
}

.verify-member-info{
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #cecece;
  background-color: rgba(244, 246, 247, 0.4);
  padding: 25px !important;
  padding-left: 40px !important;
}

.first-col-label {
  font-size: 14px;
  color: #202f35;
}

.second-col-values {
  font-size: 14px;
  font-weight: bold;
  color: #414158;
}

.EDIT-btn{
  font-size: 16px;
  font-weight: 600;
  color: #2a9d8f;
  cursor: pointer;
  margin-left: 10px;
}
.CANCEL-btn{
  font-size: 16px;
  font-weight: 600;
  color: #e76f51;
  cursor: pointer;
  margin-left: 10px;
}
.address-Membership-type{
  font-size: 20px;
  font-weight: 500;
  color: #414158;
  margin-top: 15px !important;
}

.verify-bottom-btns{
  padding: 10px
}
.verify-bottom-next{
  padding: 10px;
  padding-right: 20px;
}

@media only screen and (max-width: 800px) {
  .verify-header-spacing{
    padding: 20px;
  }
  .verify-memberInfo-spacing{
    padding: 3px;
  }
  
}

