.memberAttendance-spacing {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 25px !important;
}

.memberAttendance-back {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  max-width: 200px;
  margin-bottom: 15px;
}

.memberAttendance-header {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.memberAttendance-subHeader {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px !important ;
}

.memberAttendance-btn-createNew {
    margin-right: 20px;
}

.memberAttendance-filter-space {
  margin-top: 22px !important;
}

.memberAttendance-filter {
    cursor: pointer;
    width: 80px;
}

.memberAttendance-filter-restart {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
  width: 40px;
}

.memberAttendance-filter-searchBox{
  margin-top: 17px;
  margin-right: 10px;
}

.memberAttendance-filter-date {
  max-width: 200px;
}

.memberAttendance-filter-date-to {
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
  padding-top: 17px;
  text-align: center;
  width: 40px;
}

.memberAttendance-Absent{
  background-color: #414158;
  padding:8px;
  border-radius: 12px;
  color: #ffffff;
  padding:5px;
  width:100px;
  font-size: 14px;
}

.memberAttendance-Present{
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  padding:5px;
  width:100px;
}
