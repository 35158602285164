
.programDetails-wrapper {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 15px;
}

.programDetails-topControl-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.programDetails-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 0px;
}

.programDetails-spacing {
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-top: 25px !important;
}

.programDetails-back {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  max-width: 220px;
}


.programDetails-topBtn-spacing {
  display: inline;
  margin-right: 8px;
}

.programDetails-boxes {
  font-family: 'Work Sans', sans-serif;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #cecece;
  padding: 15px !important;
  padding-top: 20px !important;
}

.programDetails-boxes-main-title {
  font-size: 20px;
  font-weight: 600;
}

.programDetails-boxes-text {
  padding: 20px !important;
}

.programDetails-boxes-text-other {
  padding: 20px 0px 12px 0px !important;
}

.programDetails-bottom-box {
  margin-bottom: 20px !important;
}

.programDetails-emergencyContact {
  min-height: 85px;
}

.calendar-icon {
  margin-bottom: 5px;
}

.schedule-container {
  padding-bottom: 0px !important;
}
