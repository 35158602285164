.selectCategory-main-titles {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-top: 15px !important;
  margin-bottom: 13px !important;
}

.selectCategory-backbtn {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  margin-top: 10px !important;
}

.selectCategory-spacing {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 15px;
}

.selectCategory-subTitles{
  font-size: 25px;
  font-weight: 500;
  color: #333333;
}

.selectCategory-box-fee-shadow {
  background-color: lightgrey ;
}

.selectCategory-fees-title {
  font-size: 18px;
  color: #3c3c3c;
  font-weight: 600;
  padding-left: 10px;
  padding-top: 10px;
}

.selectCategory-box-fees {
  padding:5px
}

.selectCategory-table-header {
  width: 66.1px !important;
  height: 19px v;
  font-family: 'Work Sans', sans-serif v;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #2a9d8f !important;
}

.selectCategory-table-text {
  font-size: 16px;
  line-height: 1.25;
  color: #414158;
}
.selectCategory-table-text-highlight {
  font-weight: 600;
  color: #2a9d8f;
}

.selectCategory-table-spacing {
  padding: 5px 10px 2px 10px
}

.selectCategory-member {
  padding:15px !important
}

.selectCategory-family {
  padding: 0px 20px 20px 20px !important
}

.selectCategory-member-text{
  font-size: 16px;
  line-height: 1.19;
  color: #333333;
}
.selectCategory-member-text-highlight {
  font-weight: 600;
  color: #2a9d8f;
}

.radio{
  color:  #2a9d8f !important;
  margin: 0% !important
}

.selectCategory-btns{
  margin-top: 180px !important;
}

.selectCategory-btn-close {
  margin-top: 118px !important;
}

.selectCategory-btns-bottom {
  margin-top: 360px !important;
}

@media only screen and (max-width: 800px) {
  .selectCategory-spacing {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
  }
  .selectCategory-btns{
    margin-top: 20px !important;
  }
  
  .selectCategory-btn-close {
    margin-top: 18px !important;
  }
  .selectCategory-member-body {
    text-align: center;
  }
  .selectCategory-btns-bottom {
    margin-top: 40px !important;
  }
  
}
  
