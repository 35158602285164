.Payment-title {
  font-size: 24px;
  font-weight: 600;
  padding-left: 0% !important;
  color: #333333;
  margin-top: 25px !important;
}

.payment-membership-wrapper {
  padding: 26px 34px;
}

.paymentMethod-col {
  margin-right: 0px !important;
  padding: 0% !important;
  margin-top: 15px !important;
}

.payment-cash-wrapper {
  position: relative;
  /* bottom: 20px; */
  padding-top: 10px;
  padding-top: 40px;
}

.payment-cash-wrapper b {
  color: #4b9d8f;
}

.payment-cash-icon {
  width: 40px;
  padding-bottom: 6px;
  padding-right: 8px;
}
