.logins-wrapper {
  padding: 30px 45px
}

.login-logo {
  margin-left: 18px;
  margin-top: 30px;
}

.login-boxes {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  max-width: 462px;
  padding: 44px 50px;
  margin-top: 72px;
}

.login-helper-box {
  width: 462px;
  margin-top: 20px;
  padding: 20px 80px;
}

.login-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.pre-registration-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pre-registration-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  max-width: 550px;
  margin-bottom: 40px;
}

.pre-registration-body {
  margin: 0 auto !important;
  padding-top: 56px;
}

.pre-registration-text-body {
  padding-top: 30px !important;
}

.login-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: 14px;
  margin-bottom: 30px;
}

.login-error-message {
  color: #f44336;
  text-align: center;
  margin-bottom: 10px;
}

.login-button-wrapper {
  margin-top: 10px !important;
}



@media only screen and (max-width: 600px) {
  .logins-wrapper {
    padding: 30px 20px
  }  
  .login-boxes {
    margin-top: 50px;
    padding: 30px;
  }
  .login-helper-box {
    margin-top: 20px;
    padding: 30px;
  }
  .login-logo {
    margin-top: 50px;
    width: 150px;
  }
}