.taxSubmissionList-wrapper {
  padding-right: 35px;
  padding-left: 12px;
  padding-top: 25px ;
}

.taxSubmissionList-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-top: 9px;
  margin-bottom: 15px;
}


.taxes-status {
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  margin-right: 20px !important;
  padding: 2px 15px 2px 15px;
  text-transform: capitalize;
  width: 114px;
}

.taxes-status-submitted {
  background-color: #414158;
}

.taxes-status-inReview {
  background: #202F35;
}

.taxes-status-pending {
  background: #E9C46A;
}

.taxes-status-approved {
  background: rgba(42, 157, 143, 0.827251);
}

.taxes-status-missingInfo {
  background: #D52B1E;
}

.taxes-status-complete {
  background: #979797
}

.taxSubmissionList-table-filter {
  padding-right: 8px;
  padding-left: 12px;
}

.taxSubmissionList-filter-button {
  background: none;
  box-shadow: none;
  border: none;
}

.taxSubmissionList-selectedStatus {
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 5px;
  display: inline;
  border: none;
}

.taxSubmissionList-button-filter {
  height: 41px !important;
  width: 185px;
  margin-left: 9px;
  margin-top: 5px;
  border: 1px solid #000000;
  border-radius: 4px;
  border-color: darkgray;
  text-align: center;
}

.taxSubmissionList-ages-dropdown{
  font-size: 14px;
  color: lightslategrey;
  margin-right: 100px;
}

.taxSubmissionList-isap-dropdown{
  font-size: 14px;
  color: lightslategrey;
  margin-right: 100px;
  padding-right: 13px;
}

.taxSubmissionList-filter-searchBox{
  margin-top: 5px;
}

.taxSubmissionList-filter-date {
  max-width: 200px;
}

.taxSubmissionList-filter-date-to {
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
  padding-top: 17px;
  text-align: center;
  width: 40px;
}

.taxSubmissionList-filter-restart {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
  width: 40px;
}

.taxSubmissionList-activeTab {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: #e76f51;
  cursor: pointer;
}

.taxSubmissionList-deactiveTab {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 18px;
  line-height: 1.33;
  color: #737c80;
  cursor: pointer;
}

.taxSubmissionList-tabSpacing{
  border-radius: 2px;
  background-color: rgba(237, 237, 237, 0.3);
  padding-left: 5px;
  padding-right: 5px;
}

.taxSubmissionList-table-download-button {
  background: none;
  border: none;
  box-shadow: none;
}

.taxSubmissionList-table-download-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #2A9D8F;
  margin-left: 8px;
}

.submissionList-status-message {
  margin-right: 35px;
  margin-left: 35px;
  margin-top: 25px;
}


@media only screen and (max-width: 1100px) {
  .taxSubmissionList-loading-table{
    left: 45%;
    top: 100%
  }
}