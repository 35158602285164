.programsList-spacing {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 25px !important;
}

.programsList-header{
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 21px !important;
}

.programsList-btn-createNew {
    margin-right: 20px;
}

.programsList-filter {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    cursor: pointer;
}

.programsList-selectedItems{
    padding-top: 12px;
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 14px;
    color: #ffffff;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
    display:inline;
    cursor: pointer;
}

/* .programsList-searchField {
    margin-top: 12px;
} */

.programsList-btn-filter {
    height: 41px !important;
    width: 185px;
    margin-left: 9px;
    border: 1px solid #000000;
    border-radius: 4px;
    border-color: darkgray;
    text-align: center;
}

.programsList-ages-dropdown{
    font-size: 14px;
    color: lightslategrey;
    margin-right: 110px;
}

.programsList-seasons-dropdown{
    font-size: 14px;
    color: lightslategrey;
    margin-right: 82px;
}

.programsList-selectedItems{
    padding-top: 12px;
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 14px;
    color: #ffffff;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
    display:inline;
    cursor: pointer;
}

.programsList-box-selectedItems{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.programsList-hasSpotsLeft{
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 16px;
    color: #ffffff;
    font-family: 'Work Sans', sans-serif;
    border-radius: 12px;
    margin-right: 20px !important
}

.programsList-thereISNoSpotsLeft{
    background-color: #e6e6e6;
    font-size: 16px;
    color: #000000;
    font-family: 'Work Sans', sans-serif;
    border-radius: 12px;
    margin-right: 20px !important
}

.programsList-weekly {
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 14px;
  color: #ffffff;
  font-family: 'Work Sans', sans-serif;
  border-radius: 12px;
  margin-right: 20px !important
}

.programsList-tableHover{
    cursor: pointer;
}






.table-row{
    padding: 2px !important;
}

.table-head{
    width: 100% !important
}
.table-export{
    visibility: hidden;
    display: none;
}
.check{
    width: 0px !important;
    padding:0px !important;
    margin: 0% !important;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
}
.table-cell{
    
    padding-left: 15px !important;
}

.selcted-items{
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 14px;
    color: #ffffff;
    font-family: 'Work Sans', sans-serif;
    margin-top: 6px !important;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
}

.member-header{
    height: 19px;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    color: #414158;
    font-size: 24px;
    font-weight: 600;
}

.header-membership{
    margin-bottom: 0% !important;
    margin-left:0.5px !important; 
    margin-right:0px !important; 
    background: white;
    ;
}
.member-header2{
    padding-top: 28px !important;
}
.search-text{
    border-radius: 4px;
    font-family: 'Work Sans', sans-serif;
    border: solid 1px rgba(65, 65, 88, 0.15);
    width: 293px;
    height: 48px;
    font-size: 14px;
    color: rgba(32, 47, 53, 0.5);
    padding: 12px;

}
.btnSubmit-header{
    width: 128px !important ;
    height: 54px !important;
    margin-top: 19px !important
}

.search-col{
    text-align: right
}
.col-search-btn{
    text-align: right
}

.filter{
    margin-top: 22px;
    padding-right: 0% !important
}
.export-data{
     margin-top: 28px;
}
.items-header{
    cursor: pointer;
}

.Filter {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    color: #414158;
    display:inline
  }

  .Filter:hover{
    cursor: pointer;
  }

@media only screen and (max-width: 800px) {

    .col-search-btn{
        text-align: center
    }
  }
  