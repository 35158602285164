.registration-form-wrapper {
  padding: 26px 34px;
}

.registration-form-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin: 20px 0 30px 0;
}

.registration-form-members-wrapper {
  margin-top: 20px;
}

.registration-form-sub-title {
  font-style: normal;
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  color: #3c3c3c;
  margin-bottom: 13px;
}

.registration-form-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-top: 10px;
}

.registration-form-text-color {
  color: #2a9d8f;
}

.registration-form-hr {
  margin-top: 11px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.registration-form-delete-button {
  margin-right: 10px;
}

.registration-email-note {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #414158;
  margin: 5px 0;
}

.registration-email-note-red {
  font-weight: 600;
  color: #e76f51;
}

.registration-email-news {
  max-width: 90%;
  padding-top: 9px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #414158;
}

.registration-email-news-wrapper {
  padding-top: 35px;
}

.registration-password-note {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #414158;
}

.registration-password-note-red {
  font-weight: 600;
  color: #e76f51;
}

.register-address-text-highlight {
  color: #2a9d8f;
  font-size: 16px;
  font-weight: 600;
}

.registration-buttons-message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #414158;
  max-width: 55%;
}

.registration-bottom-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4b4b4b;
}

.registration-buttons-hr {
  margin-top: -8px;
}

.hidden-password-error {
  visibility: hidden;
}

.display-password-error {
  visibility: visible;
  color: #e76f51;
}

@media only screen and (max-width: 960px) {
  .registration-email-news-wrapper {
    padding-top: 0px;
    margin-left: -10px !important;
  }
  .address-title-mobile {
    padding-top: 30px !important;
  }
}

@media only screen and (max-width: 700px) {
  .registration-buttons-message {
    max-width: 100%;
  }
  .registration-step-buttons-wrapper {
    text-align: end;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .registration-form-wrapper {
    padding: 10px 10px;
  }
  .registration-form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }
  .registration-email-news {
    max-width: 85%;
  }
}
