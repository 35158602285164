.textField {
  width: 100%;
  margin-top: 6px !important;
  padding-top: 0px !important;
}

.dowpdown {
  width: 100% !important;
  margin: 0% !important;
}

.popOver-message {
  font-family: 'Work Sans', sans-serif !important;
  color: #414158;
  padding: 5px;
}

.greenCheck {
  margin-bottom: 0% !important;
}

.exception {
  width: 100%;
  margin-top: 6px !important;
  padding-top: 0px !important;
}

.btnRed {
  border-color: #e76f51 !important;
  color: #e76f51 !important;
}

.YesNo {
  color: #2a9d8f !important;
}

.MuiTypography-body1 {
  font-family: 'Work Sans', sans-serif !important;
}

.yesNoLabel {
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.search-btn {
  padding: 2px !important;
  width: 100% !important;
}

.Receive-latest-news {
  font-family: 'Work Sans', sans-serif;
  color: #414158;
  line-height: 1;
  width: 90% !important;
  margin-top: 0px !important;
}
.cancel-alert-header {
  font-family: 'Work Sans', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.87);
}
.alert-text {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #737c80;
}
.alert-header {
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20px !important;
}

.alert-LEAVE {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #2a9d8f !important;
}

.alert-STAY {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #2a9d8f !important;
}

.LEAVE {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #009688;
}

.cancel {
  border: none !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  width: 128px !important;
  height: 47px !important;
}

.btnNext {
  text-align: right !important;
}

.covid19-text-alert {
  color: #d52b1e;
}

.back-button {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  padding: 0px
}

.back-button:focus {
  outline: none;
  box-shadow:0 0 0 2px white, 0 0 0 4px #414158;
  border-radius: 2px;
}

.MuiRadio-root {
  color: #2a9d8f !important
}

.menuDropdown-button {
  text-transform: capitalize !important;
}

.custom-chip {
  padding-top: 12px;
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 5px;
  display:inline;
  cursor: pointer;
}

.custom-chip-close {
  background: none;
  border: none;
  border-radius: 50%;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 15px;
  padding-top: 0px;
}

.stepsButtons {
  padding-bottom: 23px;
  padding-top: 23px;
}

.button-loading {
  position: absolute;
  right: 23%;
  top: 7px;
}

.email-label {
  margin-top: 10px !important;
}

.cardName-wrapper{
  /* padding-bottom: 18px; */
}

@media only screen and (max-width: 417px) {
  .email-textfield, .email-button  {
    width: 100%;
  }
  .email-button {
    text-align: end;
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .btnsAlert {
    text-align: right !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 600px) {
  .MuiStepper-root {
    padding: 18px 0px 8px 0px !important
  }
}


 /* ################ defaultAlert ##################### */

 .default-alert-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  align-self: baseline;
  padding: 15px 20px 0px 20px !important;
  justify-content: flex-start;
 }

 .default-alert-body {
  font-style: normal;
  font-size: 16px;
  padding: 15px 20px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
 }

 .default-dialog-buttons {
  font-weight: 600 !important;
  color: #4b9d8f !important;
 }