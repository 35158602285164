/* Membership styles  */ 

.initial-portal-top-wrapper {
  margin: 50px 235px !important;
  max-width: 1440px !important;
  position: relative;
}

.initial-button {
  position: absolute;
  right: 0px;
}

.initial-logo {
  margin-left: 20px;
}

.initial-portal-wrapper {
  padding: 20px 10%;
  max-width: 1600px !important;
  margin: 0 auto !important;
}

.initial-portal-boxes {
  width: 300px;
  height: 237px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05), 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin: 10px !important;
  text-align: center;
  padding-top: 25px;
  cursor: pointer;
  border: none;
}

.initial-portal-boxes:focus {
  outline-color: #414158;
  outline-offset: 4px;
}

.initial-portal-box-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 20px !important;
}

.initial-portal-box-extra-message {
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}

.initial-vertical-box {
  width: 940px;
  background: #E9C46A;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05), 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 20px 30px;
  margin-top: 10px !important;
  cursor: pointer;
  border: none;
}

.initial-vertical-box:focus {
  outline-color: #414158;
  outline-offset: 4px;
}

.initial-vertical-box-icon {
  margin-right: 30px;
}

.initial-vertical-box-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}


@media only screen and (min-width: 1440px) {
  .initial-portal-top-wrapper {
    margin: 0 auto !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .initial-button {
    right: 250px;
  }
}

@media only screen and (max-width: 1200px) {
  .initial-vertical-box {
    width: 620px;
  }
}

@media only screen and (max-width: 1084px) {
  .initial-portal-top-wrapper {
    margin: 0 auto !important;
    margin-top: 50px !important;
  }
  .initial-button {
    position: relative;
    text-align: center;
    padding-top: 30px;
    padding-left: 20px;
  }
}


@media only screen and (max-width: 800px) {
  .initial-vertical-box {
    width: 477px;
    height: 100px;
  }
  .initial-portal-boxes {
    width: 145px;
    height: 122px;
    padding-top: 10px;
  }
  .initial-portal-box-title
  {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px !important;
  }
  .initial-portal-box-extra-message {
    font-size: 8px;
  }
  .initial-vertical-box-title {
    vertical-align: middle;
  }
  .boxes-hours-icons,
  .boxes-membership-icons,
  .boxes-gym-icons,
  .boxes-volunteering-icons
  {
    height: 37px;
    width: 37px;
  }
  .boxes-program-icons
  {
    height: 37px;
    width: 32px;
  }
  .initial-vertical-box-icon {
    height: 47px;
    width: 42px;
  }
  .boxes-facility-icons
  {
    height: 37px;
    width: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .initial-portal-wrapper{
    padding: 20px 1%;
  }
  .initial-portal-top-wrapper {
    margin-top: 10px !important;
  }
  .initial-logo {
    height: 83px;
    width: 168px;
  }
  .initial-button {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 506px) {
  .initial-vertical-box {
    width: 312px;
    height: 100px;
    padding: 15px 30px;
  }
  .initial-vertical-box-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 5px !important;
    display: block;
  }
  .initial-vertical-box-icon {
    margin-right: 0px;
  }
}



