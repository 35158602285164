.reports-link {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #E76F51;
    background-color: transparent;
    border: none;
}

.reports-back-buttons {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #E76F51;
    background-color: transparent;
    border: none;
}

.reports-covid19-negative{
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 12px;
    color: #ffffff;
    border-radius: 12px;
    padding: 5px 15px;
    width: 80px;
    margin-right: 20px;
  }

.reports-covid19-positive{
    background-color: #D52B1E;
    font-size: 12px;
    color: #ffffff;
    border-radius: 12px;
    padding: 5px 15px;
    width: 80px;
    margin-right: 20px;
}

.reports-covid19-header {
    padding-right: 4%;
}

.reports-covid-token {
    margin: 3px 16px 3px 0px;
}

.reports-covid-name-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
    margin-top: 7px;
}

.admin-name {
    padding-top: 7px;
}

.reports-covid19-checkInID {
    color: #4A90E2;
    cursor: pointer;
}

.reports-covid19-filter-wrapper {
    margin-bottom: 10px;
    font-family: 'Work Sans', sans-serif !important;
}

.reports-covid-date {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #414158;
    margin-top: 13px;
}

.reports-covid-date {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    color: #333333;
}

.reports-covid-phoneIcon {
    margin: 0 13px 2px 0;
}

.receipt-covid19-filter-dropdown {
    font-size: 14px;
    color: lightslategrey;
    margin-right: 75px;
}

.receipt-covid19-filter-popup {
    width: 183px;
}
.dropdown-results {
    margin-right: 92px;
}
.filter-results {
    left: -4px;
}

  