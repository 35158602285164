.today-date{
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    background-color: #414158;
    color: #ffffff;
    padding:7px;
}
.present-couter{
    color: rgba(42, 157, 143, 0.83);
    font-size: 22px;
}
.absent-couter{
    color: #e76f51;
    font-size:22px;
}



  .absent{
    background-color: #414158;
    font-size: 12px;
    color: #ffffff;
    padding:7px;
    font-family: 'Work Sans', sans-serif;
    border-radius: 100%;
    width:30px;
    margin: 5px;
    cursor: pointer;
  }


  .Taken{
    background-color: #e6e6e6;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    border-radius: 12px;
    color: #333333;
    padding:5px;
    width:100px;
  }


  .thereISNoSpotsLeft{
    background-color: #e6e6e6;
    font-size: 12px;
    color: #ffffff;
    font-family: 'Work Sans', sans-serif;
    border-radius: 12px;
    width: 50px;
}
.Attendance {
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #414158;
  }
  .absent-Attendance{
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #414158;
  }

.table-row{
    padding: 2px !important;
   
}

.table-head{
    width: 100% !important
}
.table-export{
    visibility: hidden;
    display: none;
}
.check{
    width: 0px !important;
    padding:0px !important;
    margin: 0% !important;
}
.table-cell{
    
    padding-left: 15px !important;
}

.member-header{
    height: 19px;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    color: #414158;
    font-size: 24px;
    font-weight: 600;
}


.header-membership{

    margin-bottom: 0% !important;
    margin-left:0.5px !important; 
    margin-right:0px !important; 
    background: white;
    ;
}
.member-header2{
    padding-top: 28px !important;
}
.search-text{
    border-radius: 4px;
    font-family: 'Work Sans', sans-serif;
    border: solid 1px rgba(65, 65, 88, 0.15);
    width: 293px;
    height: 48px;
    font-size: 14px;
    color: rgba(32, 47, 53, 0.5);
    padding: 12px;

}
.btnSubmit-header{
    width: 128px !important ;
    height: 54px !important;
    margin-top: 19px !important
}

.search-col{
    text-align: right
}
.col-search-btn{
    text-align: right
}

.filter2{
    margin-top: 22px;
    padding-left: 0% !important
}
.filter{
    margin-top: 22px;
    padding-right: 0% !important
}
.export-data{
     margin-top: 28px;
}
.items-header{
    cursor: pointer;
}

@media only screen and (max-width: 800px) {

    .col-search-btn{
        text-align: center
    }
  }
  