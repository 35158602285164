/* Shared CSS */
.body-boxes {
  padding: 10px;
  border: solid 1px #cecece;
  margin: 5px;
}
.boxes-labels {
  margin-top: 10px;
}
.boxes-spacing {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.Boxes-main-title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
}
/* ----------- */

.membershipInfo-boxes-spacing {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

/* Header CSS */
.Back-to-Member-list {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  max-width: 200px;
}
.verify-member-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-top: 25px;
}
.header-box {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(65, 65, 88, 0.03);
  font-size: 12px;
  font-weight: 600;
  color: #414158;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}
.member-name {
  font-size: 25px;
  font-weight: 500;
  margin-left: 20px;
}

.user-name {
  margin-left: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}

.user-type {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-left: 10px;
  border-radius: 12px;
  margin-right: 20px !important;
  padding: 2px 15px 2px 15px;
  background-color: #414158;


}
.active-dropdown-item {
  color: #2a9d8f;
  cursor: pointer;
}
.deactivated-dropdown-item {
  color: lightgray;
}

.active-dropdown-item-delete {
  color: #e76f51;
  cursor: pointer;
}

.profile-dropdown-item {
  color: #000000;
  cursor: pointer;
}

.attendanceLabel {
  font-size: 20px;
  font-weight: 600;
}

.programsLabels {
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: #2a9d8f;
  text-decoration: underline;
  margin: 15px;
  
}
/* ----------- */

/* Family Member CSS */
.paper-family-box {
  padding: 10px;
  border: solid 1px #cecece;
  margin-top: 10px;
  min-height: 423px;
  max-height: 424px;
  position: relative;
  margin: 5px;
  margin-top: 10px;
}
.family-member {
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: #2a9d8f;
  text-decoration: underline;
  margin: 15px;
}
.family-cancel-btn {
  margin-right: 15px !important;
}
.family-member-delete {
  cursor: pointer;

  font-size: 16px;
  color: #e24c4b;
  text-transform: uppercase;
}
.delete-icon {
  color: #e24c4b;
  margin-right: 3px;
  cursor: pointer;
  margin-top: -3px;
}

.familyMember-addMember {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 5px;
  width: 100%;
  margin: auto;
}
/* ----------- */

/* Other ifo CSS */
.paper-otherInfo-box {
  padding: 10px;
  border: solid 1px #cecece;
  margin-top: 10px;
  min-height: 224px;
  max-height: 225px;
  margin: 5px;
  margin-top: 10px;
}
/* ----------- */

.verifyMembership-Active {
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  margin-right: 20px !important;
  padding: 5px 15px 5px 15px;
}

.verifyMembership-Unverified {
  background-color: #414158;
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  margin-right: 20px !important;
  padding: 5px 15px 5px 15px;
}

.verifyMembership-Expiring {
  background-color: #e9c46a;
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  margin-right: 20px !important;
  padding: 5px 15px 5px 15px;
}
.verifyMembership-Expired {
  background-color: #e6e6e6;
  font-size: 14px;
  color: black;
  font-family: 'Work Sans', sans-serif;
  border-radius: 12px;
  margin-right: 20px !important;
  padding: 5px 15px 5px 15px;
}
