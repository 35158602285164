.register-spacing {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 15px;
}

.register-main-title{
  font-size: 24px;
  color: #333333;
  margin-top: 25px !important;
  margin-bottom: 13px !important;
  font-weight: 600;
  padding: 5px;
}

.register-titles{
  padding: 5px;
  font-size: 20px;
  color: #3c3c3c;
  font-weight: 600;
}

.register-address-box {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  width: 100%;
  padding: 5px !important;
}

.register-cols{
  padding: 5px 5px 0px 5px;
}


.register-family-cols {
  padding: 0px 5px 0px 5px;
  margin-top: 10px !important;
}

.register-address-text {
  color: #333333;
  font-size: 16px;
  padding: 5px
}

.register-email-text {
  color: #333333;
  font-size: 14px;
  padding: 5px
}

.register-email-text-highlight{
  color: #2a9d8f;
  font-size: 14px;
  font-weight: 600;
}

.register-address-title{
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  width: 100%;
  padding: 5px
}

.register-btns-box {
  padding: 5px !important;
}

.register-bottom-text {
  color: #333333;
  font-size: 12px;
}

.register-bottom-text-highlight {
  color: #2a9d8f;
  font-size: 12px;
  font-weight: 600;
}

.register-bottom-spacing{
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

@media only screen and (max-width: 800px) {
  .register-spacing {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 15px;
  }
}