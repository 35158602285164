.font-family-default {
  font-family: 'Work Sans', sans-serif !important;
}

.MuiTableCell-root {
  font-family: 'Work Sans', sans-serif !important;
}

.hidden {
  display: none;
  visibility: hidden;
}

.btn.focus, .btn:focus {
  outline: none !important;
  box-shadow: 0 0 0 2px #414158 !important;
}


.ml-5 {
  margin-left: 5px;
}

.commingSoon {
  font-size: 12px;
  color: #ffffff;
}

.close-icon {
  cursor: pointer;
}

.alert-btns {
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  color: #2a9d8f !important;
}

#hidden {
  display: none;
  visibility: hidden;
}

.customized-dialog-title {
  font-family: 'Work Sans', sans-serif !important;
}

.table-tabSelection {
  cursor: pointer;
}
.table-tabSelection:focus {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.09);
}

.header-mobile-menu {
  position: absolute;
  cursor: pointer;
  top: 16px;
}

.chip-close {
  background: none;
  border: none;
  border-radius: 50%;
}

.MuiInputBase-root {
  font-family: 'Work Sans', sans-serif !important;
}

/* Login Button styles */
.Rectangle-btn {
  width: 104px;
  height: 48px;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(251, 102, 70, 0.15),
    0 0 2px 0 rgba(251, 102, 70, 0.1);
  border: solid 1px #2a9d8f;
  background-color: #ffffff;
}

.login {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #2a9d8f;
  font-size: 14px;
}

.memberLogin-icon {
  color: #2a9d8f;
  margin-bottom: 6px;
  cursor: pointer;
}

.memberLogin-notAMember {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #4e4e4e;
}

.Enter-your-email-add {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  line-height: 1.19;
  color: #333333;
  margin-top: 8.2%;
  margin-bottom: 25.7%;
}

.resetPassword-text {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  line-height: 1.19;
  color: #333333;
  margin-top: 8.2%;
  margin-bottom: 5.7%;
}

.login-box {
  max-width: 465px !important;
  padding: 35px !important;
}

.txt {
  text-align: right;
}

.Admin-Login {
  font-family: 'Work Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.FORGOT-PASSWORD {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #e76f51;
}
.btnLogin {
  height: 47px !important;
  width: 379px !important ;
}

.final {
  position: absolute;
}

.links {
  text-decoration: none !important;
  color: white !important;
  width: 100%;
  font-family: 'Work Sans', sans-serif;
}

.Initial-hr {
  width: 100% !important;
}
.arrow {
  margin-bottom: 2px !important;
}

.-Back-to-Member-lis {
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
}

#sq-cvv {
  width: 100%;
}

#sq-expiration-date {
  width: 100%;
}

/* .container {
  margin: 0 auto
} */

#sq-postal-code {
  width: 100%;
}

.login-public {
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  padding-top: 2px;
}

.login-icon-public {
  margin-top: -2px;
}

#sq-ccbox {
  font-family: 'Work Sans', sans-serif;
  line-height: 1;
  position: relative;
  border-radius: 10px;
  transition: all 400ms linear;
  /* background-color: rgb(60, 121, 253); */
  margin: 0 auto;
  color: #ccc;
  font-weight: 'normal';
}

#sq-walletbox {
  margin: 0 auto;
  width: 385px;
  text-align: center;
}

#sq-card-number {
  width: 100%;
}

#cc-field-wrapper {
  margin: 0;
  display: inline-flex;
}

button.button-credit-card {
  display: block;
  line-height: 1.25em;
  vertical-align: middle;
  height: 45px;
  width: 385px;
  border-radius: 10px;
  text-align: center;
  margin: 5px auto;
  font-size: 22px;
  font-weight: 600;
  background-color: #000;
  color: #fff;
}

button.wallet-button {
  display: block;
  margin: 5px auto;
  border-radius: 10px;
  height: 45px;
  width: 300px;
  overflow: hidden;
  background-clip: border-box;
  background-position: center;
}

#form-container {
  width: 100%;
  padding: '0px';
}

/* Customize the Apple Pay on the Web button */
#sq-apple-pay {
  width: 100%;
  margin: 24px 0 16px 0;
  background-image: url(https://docs.connect.squareup.com/assets/docs/sqpaymentform/Apple_Pay_Mark_RGB_SMALL_052318-a40c688402e8a6684ee6938a380ba825e15163872bae3b9909f70ac028a9b780.png);
  background-color: black;
  background-size: 110%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  display: none;
}

/* Customize the Masterpass button */
#sq-masterpass {
  width: 100%;
  height: 48px;
  padding: 0;
  margin: 24px 0 24px;
  background-image: url('https://masterpass.com/dyn/img/acc/global/mp_mark_hor_wht.svg');
  background-color: black;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: calc((100% - 32px) / 2) 50%;
  border-radius: 4px;
  cursor: pointer;
  display: none;
}

#sq-masterpass::after {
  box-sizing: border-box;
  float: right;
  width: 32px;
  height: 48px;
  padding-top: 12px;
  content: url("data:image/svg+xml; utf8, <svg width='14' height='24' viewBox='0 0 14 24' xmlns='http://www.w3.org/2000/svg'><path d='M1.891 23.485c-.389 0-.778-.144-1.075-.436a1.46 1.46 0 0 1 0-2.102l9.141-8.944L.817 3.06a1.463 1.463 0 0 1 0-2.104 1.544 1.544 0 0 1 2.15 0l10.217 9.994a1.464 1.464 0 0 1 0 2.105L2.966 23.049a1.525 1.525 0 0 1-1.075.436' fill='#FFF' fill-rule='evenodd'/></svg>");
  background-color: #e6761f;
  border-radius: 0 4px 4px 0;
}

input::placeholder {
  color: #000;
}

.sq-input--focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.sq-input--error {
  box-shadow: inset 0px 0px 0px 3px rgba(255, 0, 0, 0.5);
}

.description {
  white-space: pre-wrap;
}

.table-links {
  color: #4a90e2 !important;
  cursor: pointer;
}

.MuiCheckbox-root{
  color: #2a9d8f !important;
}

.MuiFormHelperText-contained {
  margin: 5px 10px 0 10px !important;
}

.position-relative {
  position: relative;
}

@media only screen and (max-width: 600px) {
  .txt {
    padding-top: 20px;
    text-align: center;
  }
  .login-box {
    max-width: 400px !important;
    margin-top: 60px !important;
  }
  .btnLogin {
    height: 47px !important;
    width: 279px !important ;
  }
}

@media only screen and (max-width: 960px) {
  .card-payment-wrapper {
    margin: 0px !important;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .txt {
    padding-top: 20px;
    text-align: center;
  }
}
@media only screen and (max-width: 1100px) {
  .txt {
    padding-top: 20px;
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  #sq-expiration-date {
    width: 100%;
    max-width: 400px;
  }

  #sq-cvv {
    width: 100%;
    max-width: 400px;
  }
  #cc-field-wrapper {
    display: block;
  }
}

@media only screen and (min-width: 1600px) {
  .app-wrapper {
    max-width: 1599px;
    margin: auto;
  }
}
