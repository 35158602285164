.addnewFamily-back {
    font-size: 12px;
    font-weight: 500;
    color: #e76f51;
    width: 180px;
    cursor: pointer;
    width: 180px;
}

.addNewFamily-title {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin-top: 25px !important;
    margin-bottom: 13px !important;
}

.addNewFamily-body-text {
    font-size: 14px;
    line-height: 1.33;
    color: #4b4b4b;
}

.addNewFamily-body-text-highlight {
    font-size: 14px;
    line-height: 1.33;
    color: #2a9d8f;
    font-weight: 600
}

.addNewFamily-paragraph {
  font-size: 18px;
  color: #333333;
  margin-top: 15px;
}

.addNewFamily-spacing{
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 15px;
}

.addNewFamily-form-spacing {
    padding-right: 25px;
    padding-left: 25px;
}

.addNewFamily-barcode-spacing {
    margin-top: 30px;
}

.addNewFamily-barcode {
    padding-top: 10px;
}

.addNewFamily-Bottom-text {
    font-size: 12px;
    line-height: 1.33;
    color: #4b4b4b;
}

.addNewFamily-Bottom-text-highlight {
    font-size: 12px;
    line-height: 1.33;
    color: #2a9d8f;;
    font-weight: 600
}

.addNewFamily-btn {
    min-height: 30vh;
    padding: 20px
  }
  
.addNewFamily-btn-done{
    margin-top: 30vh;
}

  

@media only screen and (max-width: 800px) {
    .addNewFamily-spacing{
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 15px;
    }
    
    .addNewFamily-form-spacing {
        padding-right: 5px;
        padding-left: 5px;
    }
  }