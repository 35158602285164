.submitedAttendance-spacing {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 25px !important;
}

.submitedAttendance-back {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  max-width: 180px;
  margin-bottom: 15px;
}

.submitedAttendance-btn-createNew {
    margin-right: 20px;
}

.submitedAttendance-header {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px !important ;
}

.submitedAttendance-filter {
    cursor: pointer;
    width: 80px;
}

.submitedAttendance-filter-space {
  margin-top: 22px !important;
}

.submitedAttendance-filter-date {
  max-width: 200px;
}

.submitedAttendance-filter-date-to {
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
  padding-top: 17px;
  text-align: center;
  width: 40px;
}

.submitedAttendance-filter-restart {
  cursor: pointer;
  padding-top: 15px;
  text-align: center;
  width: 40px;
}

 .submitedAttendance-Absent{
     background-color: #414158;
    font-size: 12px;
    color: #ffffff;
    padding: 7px;
    border-radius: 100%;
    width:30px;
    margin: 5px;
  }

  .submitedAttendance-Present{
     background-color: #4faea2;
    font-size: 12px;
    color: #ffffff;
    padding: 7px;
    border-radius: 100%;
    width:30px;
    margin: 5px;
  }




