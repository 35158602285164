.reservation {
    padding: 24px;
}

.edit-link {
    color:#2A9D8F;
    padding: 0 5px;
    border: 0;
    text-decoration: underline;
    font-size: 18px;
    line-height: 21px;
    background-color: transparent;
}

.admin-buttons {
    display: flex;
    gap: 9px;
    margin-top: 24px;
}

.reservation-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 238px);
    grid-gap: 16px;
}

.reservation .main-title  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 17px;
}

.reservation .main-title h1 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 39px;
}

.reservation .main-title .description {
    margin-bottom: 27px;
    font-size: 18px;
    line-height: 21px;
}

.reservation__card {
    display: flex;
    cursor: pointer;
    flex-flow: column;
    width: 238px;
    height: 227px;
    background: #FBFBFB;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.0722793);
}

.reservation__card-title {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    padding: 8px 15px 12px;
}

.reservation__card-description {
    color: #E76F51;
    font-size: 14px;
    line-height: 16px;
    padding: 0 15px 13px;
}

.reservation__card-more-opt {
    background-color: transparent;
    border: none;
    position: absolute!important;
    right: 0;
    top: 0;
}

.reservation__card-thumbnail {
    order: -1;
}

.reservation__card-thumbnail img{
    width: 100%;
}

.add-new-btn {
    display: flex;
    cursor: pointer;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    background: #FBFBFB;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.0722793);
}

.add-new-btn_img img{
    width: 59px;
}

.add-new-btn_label {
    margin-top: 25px;
    font-size: 16px;
    line-height: 19px;
    color: #E76F51;
    font-weight: 500;
}