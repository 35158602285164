.admin-name {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  color: #414158;
}
.admin-col-folder {
  margin-top: 12px !important;
}
.dropdown-item {
  cursor: pointer;
}
.dropdown-user-icon {
  min-width: 140px;
}


.linkToRegister {
  font-family: 'Work Sans', sans-serif;
  text-decoration: none !important;
  color: black;
}

.linkToRegister:hover {
  cursor: pointer;
  text-decoration: none !important;
  color: black;
}

.linkToRegister:focus {
  box-shadow:0 0 0 2px white, 0 0 0 4px #414158;
  width: 100%;
  outline: none;
  border-radius: 2px;
}

.logout {
  font-family: 'Work Sans', sans-serif;
  cursor: pointer;
}

#NO {
  display: none;
  visibility: hidden;
}

.navShadow {
  padding: 10px;
  position: relative;
  z-index: 10;
}

.mobile-text-center {
  text-align: center;
}

.header-mobile {
  visibility: hidden;
  display: none;
}

.header-links {
  width: 100%;
}

.header-links:focus {
  outline: none;
  box-shadow: 0 0 0 2px #414158;
  border-radius: 2px;
}

.logo-button {
  background: none;
  border: none;
  box-shadow: none;
}

.logo-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #414158;
  border-radius: 2px;
}


@media only screen and (max-width: 1100px) {
  .header-mobile {
    visibility: visible;
    display: block;
  }
  .header-desktop {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (min-width: 1600px) {
  .header-mobile, 
  .header-desktop {
    max-width: 1600px;
    margin: auto;
  }
}
