.questionnaire-wrapper {
  padding-right: 35px;
  padding-left: 35px ;
  padding-top: 25px ;
}

.taxes-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
}

.taxes-sub-title {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #717181;
  margin-top: 10px;
  margin-bottom: 24px;
}

.taxes-text-red {
  color: #E76F51;
}

.taxes-question {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 15px;
  margin-top: 10px;
}

.tax_custom_hr {
  width: 100%;
}

.custom-error {
  color: #f44336;
}

.hidden {
  visibility: hidden;
  display: none !important; 
}

.questionnaire-important-text {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #E76F51;
  padding: 20px 0;
}

.add-new-address {
  background: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #2A9D8F;
  margin: 15px 0;
}

.add-icon {
  vertical-align: top;
  margin-right: 13px;
  margin-top: 1.5px;
}

.taxes-custom-radio-wrapper {
  margin-left: -10px !important;
}

.taxes-custom-radio-label {
  text-transform: capitalize;
  margin-left: -10px;
}

.radio-sub-label {
  display: block;
  padding-left: 40px;
  margin-top: -15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
}

.taxes-consent-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  padding-bottom: 10px;
}

.taxes-checkboxes-wrapper {
  margin-left: -10px !important;
}

.taxes-checkbox-label {
  width: 95%;
  padding-top: 7px;
}

.taxes-consent-textFields {
  padding-top: 23px;
  padding-bottom: 30px;
}

.add-new-address-text {
  padding-top: 5px;
  padding-left: 9px;
  vertical-align: middle;
}

.remove-new-address-text {
  padding-top: 5px;
  padding-left: 9px;
  vertical-align: middle;
  color: #f44336;
}

.taxes-custom-textfield-wrapper {
  margin-left: 30px !important;
  margin-top: 10px !important;
}

.taxes-custom-button-wrapper {
  margin-left: 30px !important;
  margin-top: 10px !important;
}

.taxes-question-red {
  color: #E76F51;
}

.upload-dialog-title {
  font-style: normal ;
  font-weight: 600;
  font-size: 16px ;
  line-height: 24px ;
  letter-spacing: 0.15px ;
  color: #333333 ;
  font-family: 'Work Sans' ;
  padding: 20px 24px;
}

.upload-dialog-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  box-shadow: none;
}

.upload-dialog-box {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  padding: 30px 10px 15px 10px
}

.upload-dialog-box-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
}

.upload-dialog-box-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #979797;
  margin-bottom: 0px;
  margin-top: 19px;
}

.upload-dialog-upload-button {
  width: 170px;
  height: 34px;
  background: #2A9D8F;
  border-radius: 2px;
  color: #ffffff;
  vertical-align: middle;
  position: relative;
  cursor: pointer !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  border: none;
  font-family: 'Work Sans', sans-serif !important;
}
.upload-dialog-upload-button:focus {
  outline-offset: 4px;
  outline-color: #2A9D8F;
}

.upload-dialog-upload-button:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}


.upload-dialog-input-button  {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  font-size: 24px;
  cursor: pointer;
}

.upload-dialog-document {
  border: 1px solid rgba(142, 145, 177, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 9px;
  margin-right: 6px;
  padding: 1px 8px;
}

.upload-dialog-document-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #1F2D41;
  padding-left: 5px;
}

.upload-dialog-remove-file {
  margin-left: 5px;
  background: none;
  border: none;
  box-shadow: none;
  margin-top: 1px;
}

.taxes-question-mark {
  margin-bottom: 4px;
  margin-left: 5px;
  cursor: pointer;
}


.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  z-index: 2000 !important;
  min-width: 420px;
  padding: 15px 25px 10px 25px  !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22), 0px 24px 24px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.MuiTooltip-arrow {
  font-size: 14px !important;
  color: #ffffff !important;
}

.taxes-consent-message {
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #2A9D8F;
  padding-top: 60px;
}

.taxes-uploads-box {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  padding: 19px 23px;
}

.taxes-uploads-box-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 10px !important;
}

.taxes-important-review-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #E76F51;
  padding-left: 8px;
  padding-top: 3px;
  font-style: italic;
}

.taxes-formID {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #202F35;
  margin-bottom: 20px;
}

.upload-dialog-permissions {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #333333;
  margin-top: 30px;
}

.submissionList-welcome-wrapper {
  padding-right: 35px;
  padding-left: 35px ;
  padding-top: 25px ;
}

.submissionList-welcome-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #333333;
  margin-bottom: 20px;
}

.submissionList-welcome-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
}

.submissionList-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 20px;
}

.submissionList-table-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #414158;
}

.submissionList-list-wrapper {
  padding-right: 35px;
  padding-left: 12px;
  padding-top: 25px ;
}

.submissionList-table-view {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #2A9D8F;
}

.taxes-status {
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
  margin-right: 20px !important;
  padding: 2px 15px 2px 15px;
}

.taxes-status-submitted {
  background-color: #414158;
}

.taxes-status-declined,
.taxes-status-craIssue,
.taxes-status-missingInfo {
  background-color: #D52B1E;
}

.taxes-status-inReview {
  background: #202F35;
}

.taxes-status-pending {
  background: #E9C46A;
}

.taxes-status-approved {
  background: rgba(42, 157, 143, 0.827251);
}


.taxes-status-complete {
  background: #979797
}

.submissionList-list-status-message {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  padding: 17px 13px;
  margin: 0px 12px 20px 12px;
}

.submissionList-list-status-text {
  max-width: 90%;
}

.taxes-view-documents-button {
  background: none;
  border: none;
  box-shadow: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #2A9D8F;
  margin-right: 24px;
}

.taxes-confirm-submission-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  padding: 23px 0 24px 24px;
}

.taxes-confirm-submission-note {
  max-width: 90%;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 30px;
}

.taxes-confirm-submission-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #E76F51;
}

.taxes-confirm-submission-status {
  background: #E9C46A;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #202F35;
  padding: 6px 11px;
  margin-left: 16px;
  vertical-align: middle;
  display: inline-block;
}

.taxes-confirm-submission-box {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  padding: 16px 13px;
}

.taxes-information-icon {
  margin-right: 8px;
  margin-bottom: 2px;
}

.taxes-confirm-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  padding: 27px 0 24px 0;
}

.taxes-mailto {
  background: none;
  border: none;
  box-shadow: none;
  text-decoration: underline;
  color: #2A9D8F;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.taxes-bank-void-text {
  position: absolute;
  top: 28%;
  left: 35%;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #7D9AC4;
}

.taxes-lock-icon {
  padding-top: 95px;
  padding-bottom: 24px;
  text-align: center;
}

.taxes-lock-text {
  padding-bottom: 24px;
  max-width: 390px !important;
  margin: 0 auto !important;
  text-align: center;
}

.taxes-confirm-submission-button {
  margin-right: 10px;
}

.taxes-submission-message {
  margin-top: 20px !important;
  max-width: 90%;
}



.taxSubmissionList-status-ball-submitted {
  margin-left: 8px;
  color: #414158;
}

.taxSubmissionList-status-ball-inReview {
  margin-left: 8px;
  color: #202F35;
}

.taxSubmissionList-status-ball-pending {
  margin-left: 8px;
  color: #E9C46A;
}

.taxSubmissionList-status-ball-approved {
  margin-left: 8px;
  color: rgba(42, 157, 143, 0.827251);
}

.taxSubmissionList-status-ball-missingInfo,
.taxSubmissionList-status-ball-craIssue,
.taxSubmissionList-status-ball-declined 
 {
  margin-left: 8px;
  color: #D52B1E;
}


.taxSubmissionList-status-ball-complete {
    margin-left: 8px;
  color: #979797
}


.taxSubmissionList-loading-table{
  position: absolute;
  left: 55%;
  margin-top: 20px;
}


@media only screen and (max-width: 920px) {
  .taxes-checkbox-label {
    width: 89%;
  }
}

@media only screen and (max-width: 600px) {
  .questionnaire-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
  .taxes-lock-icon {
    padding-top: 35px;
  }
  .taxes-checkbox-label {
    width: 85%;
  }
}

@media only screen and (max-width: 460px) {
  .taxes-confirm-submission-button {
    margin-bottom: 10px;
  }
  .taxes-view-docs-header {
    text-align: center;
  }
  .taxes-submission-message {
    max-width: 100%;
    text-align: center;
  }
}

