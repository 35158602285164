.addNewAttendance-spacing {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 25px !important;
}

.addNewAttendance-back {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  max-width: 180px;
  margin-bottom: 15px;
}

.addNewAttendance-btn-createNew {
    margin-right: 20px;
}

.addNewAttendance-header {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}


.addNewAttendance-filter {
    cursor: pointer;
    width: 80px;
}

.addNewAttendance-filter-space {
  margin-top: 22px !important;
}
.addNewAttendance-present {
    margin: 5px 8px 5px 5px ;
    display: inline;
    color: rgba(42, 157, 143, 0.83);
    font-size: 22px;
}
.addNewAttendance-absent {
    margin: 5px;
    display: inline;
    color: #e76f51;
    font-size:22px;
}

.addNewAttendance-filter-spacing {
    padding-right: 15px;
    padding-top: 20px;
}



.addNewAttendance-today-date{
    font-size: 16px;
    background-color: #414158;
    color: #ffffff;
    padding:7px;
}

 .addNewAttendance-present-btn{
    background-color: #4faea2;
    font-size: 12px;
    opacity: .50;
    color: #ffffff;
    padding: 7px;
    border-radius: 100%;
    width:30px;
    margin: 5px;
    cursor: pointer;
  }
  .addNewAttendance-present-btn:hover {
        opacity: 1;
  }

  .addNewAttendance-present-btn-active{
    background-color: #4faea2;
    font-size: 12px;
    opacity: 1;
    color: #ffffff;
    padding: 7px;
    border-radius: 100%;
    width:30px;
    margin: 5px;
    cursor: pointer;
  }

  .addNewAttendance-absent-btn{
    background-color: #414158;
    opacity: .70;
    font-size: 12px;
    color: #ffffff;
    padding: 7px;
    border-radius: 100%;
    width:30px;
    margin: 5px;
    cursor: pointer;
  }
   .addNewAttendance-absent-btn:hover {
        opacity: 1;
  }

   .addNewAttendance-absent-btn-active{
    background-color: #414158;
    opacity: 1;
    font-size: 12px;
    color: #ffffff;
    padding: 7px;
    border-radius: 100%;
    width:30px;
    margin: 5px;
    cursor: pointer;
  }

 .addNewAttendance-Taken{
    background-color: #e6e6e6;
    font-size: 14px;
    border-radius: 12px;
    color: #333333;
    padding:5px;
    width:100px;
  }

  .addNewAttendance-Absent{
    background-color: #414158;
    padding:8px;
    border-radius: 12px;
    color: #ffffff;
    padding:5px;
    width:100px;
    font-size: 14px;
  }

  .addNewAttendance-Present{
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 14px;
    color: #ffffff;
    border-radius: 12px;
    padding:5px;
    width:100px;
  }

  .addNewAttendance-errorMessage {
    color: #e76f51;
    font-size: 18px;
  }
 