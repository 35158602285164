.memberList-spacing {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 25px !important;
}

.memberList-header{
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 21px !important;
}

.loading-wrapper {
  position: absolute;
  left: 45%;
  top: 43%
}

.memberList-btn-createNew {
    margin-right: 20px;
}

.memberList-tableselect {
    cursor: pointer;
}

.memberList-filter {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    cursor: pointer;
}

.memberList-selectedItems{
    padding-top: 12px;
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 14px;
    color: #ffffff;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
    display:inline;
    cursor: pointer;
}

.memberList-box-selectedItems{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.memberList-btn-filter {
    height: 41px !important;
    width: 185px;
    margin-left: 9px;
    border: 1px solid #000000;
    border-radius: 4px;
    border-color: darkgray;
    text-align: center;
}

.memberList-status-dropdown{
    font-size: 14px;
    color: lightslategrey;
    margin-right: 94px;
}

.memberList-item-dropdown {
    width: 185px;
    margin-left: -3px !important;
    margin-top: 5px !important;
}


.memberList-table-header {
    background-color: rgba(65, 65, 88, 0.03);;
}


.memberList-Active{
    background-color: rgba(42, 157, 143, 0.83);
    font-size: 14px;
    color: #ffffff;
    border-radius: 12px;
    margin-right: 20px !important;
    padding: 2px 15px 2px 15px;

  }
  
  .memberList-Unverified{
    background-color: #414158;
    font-size: 14px;
    color: #ffffff;
    border-radius: 12px;
    margin-right: 20px !important;
    padding: 2px 15px 2px 15px;
  }
  
  .memberList-Expiring{
      background-color: #e9c46a;
      font-size: 14px;
      color: #ffffff;
      border-radius: 12px;
      margin-right: 20px !important;
      padding: 2px 15px 2px 15px;

  }
  .memberList-Expired{
      background-color: #e6e6e6;
      font-size: 14px;
      color: black;
      border-radius: 12px;
      margin-right: 20px !important;
      padding: 2px 15px 2px 15px;
  }
  
@media only screen and (max-width: 800px) {
    .memberList-spacing {
        padding-right: 0px !important;
        padding-left: 15px !important;
        padding-top: 25px !important;
    }
}

.filter-mobile {
  visibility: hidden;
  display: none;
}

@media only screen and (max-width: 600px) {
  .filter-desktop {
    visibility: hidden;
    display: none;
  }
  .filter-mobile {
    visibility: visible;
    display: block;
  }
}