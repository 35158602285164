body {
  margin: 0;
  font-family: 'Work Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.payment-form {
  position: relative;
}

iframe {
  z-index: -1 !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}
