.receipts-header-spacing {
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-top: 25px !important;
}

.receipts-back {
  font-size: 12px;
  font-weight: 500;
  color: #e76f51;
  cursor: pointer;
  width: 155px;
}

.receipts-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-top: 25px !important;
}

.reports-receipts-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.receipts-search {
  margin-right: 20px;
  padding-top: 15px;
}

.receipts-filter {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  cursor: pointer;
  width: 125px;
}

.receipts-filter-searchBox{
  margin-top: 5px;
}

.receipts-filter-date {
  max-width: 200px;
}

.receipts-filter-date-to {
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
  padding-top: 17px;
  text-align: center;
  width: 40px;
}

.receipt-print-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.receipts-filter-restart {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: center;
  width: 40px;
}

.receipt-alert-title{
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.receipt-alert-icon{
  color: rgba(42, 157, 143, 0.83);
}

.receipt-alert-box{
  padding: 10px;
  margin-top: 15px;
}

.receipt-alert-successfull-text {
  color: rgba(42, 157, 143, 0.83);
  margin-left: 15px;
  padding-top: 5px;
}

.receipt-alert-box-title{
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
}

.receipt-box-body {
  padding:5px
}

.receipt-alert-body-title {
  font-size: 14px;
}

.receipt-alert-body-text {
  font-size: 14px;
  font-weight: bold;
}

.receipts-alert-line{
  height: 5px !important;
  font-weight: bold !important;
}

.receipt-sendEmail-btn{
  padding-right: 8px;
}

.receipt-table-hover {
  cursor: pointer;
}

.receipt-table-approved {
  background-color: rgba(42, 157, 143, 0.83);
  font-size: 12px;
  color: #ffffff;
  border-radius: 12px;
  text-align: center;
  width: 80px;
  padding: 5px
}
.receipt-table-canceled {
  background-color: #e76f51;
  font-size: 12px;
  color: #ffffff;
  border-radius: 12px;
  text-align: center;
  width: 80px;
  padding: 5px;
  text-transform: capitalize;
}

.receipt-btn-filter {
    height: 41px !important;
    width: 185px;
    margin-left: 9px;
    margin-right: 18px;
    margin-top: 5px;
    border: 1px solid #000000;
    border-radius: 4px;
    border-color: darkgray;
    text-align: center;
}

.receipt-ages-dropdown{
    font-size: 14px;
    color: lightslategrey;
    margin-right: 100px;
}
.covid19-tabs {
  margin-left: -20px !important;
}

.ageGroup-filter {
    height: 41px !important;
    width: 210px;
    margin-left: 9px;
    margin-top: 5px;
    border: 1px solid #000000;
    border-radius: 4px;
    border-color: darkgray;
    text-align: center;
}


.ageGroup-input {
  width: 208px;
}

.ageGroup-input-icon {
    font-size: 14px;
    color: lightslategrey;
    margin-right: 90px;
}

.ageGroup-filter-popup {
  width: 210px;
}

.status-filter {
  height: 41px !important;
    width: 175px;
    margin-left: 9px;
    margin-top: 5px;
    border: 1px solid #000000;
    border-radius: 4px;
    border-color: darkgray;
    text-align: center;
}

.status-filter-input {
  width: 174px;
}

.status-filter-popup {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 38px, 0px);
  width: 175px;
}

@media only screen and (max-width: 800px) {

  .receipts-header-spacing  {
      padding-right: 0px !important;
      padding-left: 15px !important;
      padding-top: 25px !important;
  }
}