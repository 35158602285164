.settings-header-spacing {
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-top: 25px !important;
}

.settings-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.settings-search {
  margin-right: 20px;
}

.settings-filter {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  cursor: pointer;
  width: 125px;
}

.settings-box-selectedItems {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.settings-item-dropdown {
  width: 190px;
  margin-left: -3px !important;
  margin-top: 5px !important;
}

.settings-searchField {
  margin-top: 12px;
}

.settings-btn-filter {
  height: 41px !important;
  width: 185px;
  margin-left: 9px;
  border: 1px solid #000000;
  border-radius: 4px;
  border-color: darkgray;
  text-align: center;
  margin-top: 12px;
}

.settings-users-dropdown {
  font-size: 14px;
  color: lightslategrey;
  margin-right: 69px;
}

.settings-selectRows {
  cursor: pointer;
}

.settings-edit-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}
.settings-edit {
  margin-right: 4px;
  cursor: pointer;
  text-transform: uppercase ;
  color: #333333;
}

.settings-delete {
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: #e24c4b;
  text-transform: uppercase;
}
.settings-delete-icon {
  color: #e24c4b;
  margin-right: 3px;
  cursor: pointer;
}

.settings-edit-body {
  padding: 20px !important;
}
.settings-delete-body {
  font-family: 'Work Sans', 'sans-serif';
  padding: 5px !important;
  font-size: 16px;
}

.settings-delete-title {
  font-family: 'Work Sans', 'sans-serif';
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.settings-edit-deleteIcon {
  color: #e24c4b;
  cursor: pointer;
}

.settings-btns-body {
  font-family: 'Work Sans', 'sans-serif';
  padding-right: 30px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.settings-btns-body-delete {
  font-family: 'Work Sans', 'sans-serif';
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.settings-programSelection {
  cursor: pointer;
}

.settings-programSelection:hover {
  color: #279d8f;
}

.settings-deleteErrorMessage {
  color: #e76f51;
  margin-left: 20px;
}
